import _ from 'lodash';
import { fusionConfigurationPromotionMock } from '../../components/fusion-configuration-promotion-table-card/fusion-configuration-promotion-mock';
import { FusionPromotionResponse } from '../../components/fusion-configuration-promotion-table-card/interfaces';
import { CCApiService } from './api-service';
import { UIConfig } from './config-service';

export class FusionConfigurationPromotionService {
  static ApiService: CCApiService;
  static apiBasePath: string;
  static fusionPromotionApi: string;
  static mockResults: FusionPromotionResponse[];

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.mockResults = fusionConfigurationPromotionMock();
  }

  static async getPromotions(customerId, filter: {}, mockMode = false): Promise<any> {
    if (mockMode) {
      return Promise.resolve(fusionConfigurationPromotionMock(filter));
    }
    this.fusionPromotionApi = `${this.apiBasePath}/customers/${customerId}/configuration-promotions`;
    return this.ApiService.performFetch(`${this.fusionPromotionApi}?${new URLSearchParams(filter)}`).then(
      (result) => result
    );
  }

  static async getPromotion(
    customerId: string,
    promotionId: string,
    mockMode = false
  ): Promise<FusionPromotionResponse> {
    if (mockMode) {
      return Promise.resolve(_.find(this.mockResults, { id: promotionId }));
    }
    this.fusionPromotionApi = `${this.apiBasePath}/customers/${customerId}/configuration-promotions`;
    return this.ApiService.performFetch(`${this.fusionPromotionApi}/${promotionId}`).then((result) => result);
  }

  static async deletePromotion(customerId: string, promotionId: string): Promise<void> {
    this.fusionPromotionApi = `${this.apiBasePath}/customers/${customerId}/configuration-promotions`;
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(`${this.fusionPromotionApi}/${promotionId}`, fetchOptions);
  }
}
