import _ from 'lodash';
import { FusionPromotionResponse, FusionPromotionStates } from './interfaces';
import { fusionInstaceTypes } from '../../services/api/managed-fusion-api-service';

let mockResults: any;

const createPromotionMock = (index: number): FusionPromotionResponse => {
  const promotionStateKeys = Object.keys(FusionPromotionStates);
  return {
    id: `d725246b-86f2-4ab3-9f43-48d04bd8c8a${index + 1}`,
    state: promotionStateKeys[_.random(0, promotionStateKeys.length - 1)],
    sourceIntegratedSystem: {
      id: 'd725246b-86f2-4ab3-9f43-48d04bd8c8b1',
      name: 'Source Integrated System',
      type: _.sample([fusionInstaceTypes.MANAGED_FUSION, fusionInstaceTypes.SELF_HOSTED_FUSION]),
    },
    targetIntegratedSystem: {
      id: 'd725246b-86f2-4ab3-9f43-48d04bd8c8b2',
      name: 'Target Integrated System',
      type: _.sample([fusionInstaceTypes.MANAGED_FUSION, fusionInstaceTypes.SELF_HOSTED_FUSION]),
    },
    prUrl: 'https://github.com/lucidworks/one-platform-deployment/pull/1486',
    createdBy: 'user1234',
    creationDate: '2025-01-07T17:58:06.028804Z',
    approvedBy: 'user5654',
    approvedDate: '2025-01-08T17:58:06.028804Z',
    lastModified: '2025-01-09T17:58:06.028804Z',
  };
};

export const fusionConfigurationPromotionMockContent = (): any => {
  const itemCount = 25;
  return {
    content: _.times(itemCount, (index) => createPromotionMock(index)),
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageNumber: 0,
      pageSize: 10,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalPages: 3,
    totalElements: itemCount,
    last: false,
    size: 10,
    number: 0,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    numberOfElements: itemCount,
    first: true,
    empty: false,
  };
};

export const fusionConfigurationPromotionMock = (filter: any = { page: 0, size: 10 }): any => {
  if (_.isEmpty(mockResults)) {
    mockResults = fusionConfigurationPromotionMockContent();
  }
  if (!filter?.page) {
    filter.page = 0;
  }
  const filteredContent = _.slice(mockResults.content, filter.page * filter.size, (filter.page + 1) * filter.size);

  return {
    ...mockResults,
    content: filteredContent,
    pageable: {
      ...mockResults.pageable,
      sort: {
        ...mockResults.pageable.sort,
        empty: filteredContent.length === 0,
      },
      pageNumber: filter.page,
      pageSize: filter.size,
      offset: filter.page * filter.size,
    },
    last: filter.page === mockResults.totalPages - 1,
    size: filter.size,
    number: filter.page,
    sort: {
      ...mockResults.sort,
      empty: filteredContent.length === 0,
    },
    numberOfElements: filter.size,
    first: filter.page === 0,
    empty: filteredContent.length === 0,
  };
};
